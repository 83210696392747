define("discourse/plugins/discourse-ai/discourse/widgets/ai-bot-header-panel-wrapper", ["exports", "@ember/object", "discourse/widgets/render-glimmer", "discourse/widgets/widget", "@ember/template-factory"], function (_exports, _object, _renderGlimmer, _widget, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let AiBotHeaderPanelWrapper = _exports.default = (_class = class AiBotHeaderPanelWrapper extends _widget.default {
    buildAttributes() {
      return {
        "data-click-outside": true
      };
    }
    html() {
      return [new _renderGlimmer.default(this, "div.widget-component-connector", (0, _templateFactory.createTemplateFactory)(
      /*
        <AiBotHeaderPanel @closePanel={{@data.closePanel}} />
      */
      {
        "id": "w7fEG46j",
        "block": "[[[8,[39,0],null,[[\"@closePanel\"],[[30,1,[\"closePanel\"]]]],null]],[\"@data\"],false,[\"ai-bot-header-panel\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/widgets/ai-bot-header-panel-wrapper.js",
        "isStrictMode": false
      }), {
        closePanel: this.closePanel
      })];
    }
    closePanel() {
      this.sendWidgetAction("hideAiBotPanel");
    }
    clickOutside() {
      this.closePanel();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "closePanel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closePanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickOutside", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clickOutside"), _class.prototype)), _class);
});