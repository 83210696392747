define("discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-selector", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/lib/key-value-store", "select-kit/components/dropdown-select-box", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _service, _keyValueStore, _dropdownSelectBox, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function isBotMessage(composer1, currentUser1) {
    if (composer1 && composer1.targetRecipients && currentUser1.ai_enabled_chat_bots) {
      const reciepients1 = composer1.targetRecipients.split(",");
      return currentUser1.ai_enabled_chat_bots.any(bot1 => reciepients1.any(username1 => username1 === bot1.username));
    }
    return false;
  }
  let BotSelector = _exports.default = (_class = (_class2 = class BotSelector extends _component.default {
    static shouldRender(args1, container1) {
      return container1?.currentUser?.ai_enabled_personas && isBotMessage(args1.model, container1.currentUser);
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _defineProperty(this, "STORE_NAMESPACE", "discourse_ai_persona_selector_");
      _defineProperty(this, "preferredPersonaStore", new _keyValueStore.default(this.STORE_NAMESPACE));
      if (this.botOptions && this.composer) {
        let personaId1 = this.preferredPersonaStore.getObject("id");
        this._value = this.botOptions[0].id;
        if (personaId1) {
          personaId1 = parseInt(personaId1, 10);
          if (this.botOptions.any(bot1 => bot1.id === personaId1)) {
            this._value = personaId1;
          }
        }
        this.composer.metaData = {
          ai_persona_id: this._value
        };
      }
    }
    get composer() {
      return this.args?.outletArgs?.model;
    }
    get botOptions() {
      if (this.currentUser.ai_enabled_personas) {
        return this.currentUser.ai_enabled_personas.map(persona1 => {
          return {
            id: persona1.id,
            name: persona1.name,
            description: persona1.description
          };
        });
      }
    }
    get filterable() {
      return this.botOptions.length > 4;
    }
    get value() {
      return this._value;
    }
    set value(newValue1) {
      this._value = newValue1;
      this.preferredPersonaStore.setObject({
        key: "id",
        value: newValue1
      });
      this.composer.metaData = {
        ai_persona_id: newValue1
      };
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="gpt-persona">
        <DropdownSelectBox
          class="persona-selector__dropdown"
          @value={{this.value}}
          @content={{this.botOptions}}
          @options={{hash icon="robot" filterable=this.filterable}}
        />
      </div>
    
  */
  {
    "id": "TcbpLBxH",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"gpt-persona\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"persona-selector__dropdown\"]],[[\"@value\",\"@content\",\"@options\"],[[30,0,[\"value\"]],[30,0,[\"botOptions\"]],[28,[32,1],null,[[\"icon\",\"filterable\"],[\"robot\",[30,0,[\"filterable\"]]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-selector.js",
    "scope": () => [_dropdownSelectBox.default, _helper.hash],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});