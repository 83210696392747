define("discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", ["exports", "discourse/models/composer", "I18n"], function (_exports, _composer, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.composeAiBotMessage = composeAiBotMessage;
  function composeAiBotMessage(targetBot, composer) {
    const currentUser = composer.currentUser;
    let botUsername = currentUser.ai_enabled_chat_bots.find(bot => bot.model_name === targetBot).username;
    composer.focusComposer({
      fallbackToNewTopic: true,
      openOpts: {
        action: _composer.default.PRIVATE_MESSAGE,
        recipients: botUsername,
        topicTitle: _I18n.default.t("discourse_ai.ai_bot.default_pm_prefix"),
        archetypeId: "private_message",
        draftKey: _composer.default.NEW_PRIVATE_MESSAGE_KEY,
        hasGroups: false,
        warningsDisabled: true
      }
    });
  }
});