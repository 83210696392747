define("discourse/plugins/discourse-ai/discourse/lib/copy-conversation", ["exports", "discourse/lib/ajax", "discourse/lib/utilities", "discourse-i18n"], function (_exports, _ajax, _utilities, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  async function _default(topic, fromPostNumber, toPostNumber) {
    await (0, _utilities.clipboardCopyAsync)(async () => {
      const text = await generateClipboard(topic, fromPostNumber, toPostNumber);
      return new Blob([text], {
        type: "text/plain"
      });
    });
  }
  async function generateClipboard(topic, fromPostNumber, toPostNumber) {
    const stream = topic.get("postStream");
    let postNumbers = [];
    // simpler to understand than Array.from
    for (let i = fromPostNumber; i <= toPostNumber; i++) {
      postNumbers.push(i);
    }
    const postIds = postNumbers.map(postNumber => {
      return stream.findPostIdForPostNumber(postNumber);
    });

    // we need raw to construct so post stream will not help

    const url = `/t/${topic.id}/posts.json`;
    const data = {
      post_ids: postIds,
      include_raw: true
    };
    const response = await (0, _ajax.ajax)(url, {
      data
    });
    let buffer = [];
    buffer.push("<details class='ai-quote'>");
    buffer.push("<summary>");
    buffer.push(`<span>${topic.title}</span>`);
    buffer.push(`<span title='${_discourseI18n.default.t("discourse_ai.ai_bot.ai_title")}'>${_discourseI18n.default.t("discourse_ai.ai_bot.ai_label")}</span>`);
    buffer.push("</summary>");
    response.post_stream.posts.forEach(post => {
      buffer.push("");
      buffer.push(`**${post.username}:**`);
      buffer.push("");
      buffer.push(post.raw);
    });
    buffer.push("</details>");
    const text = buffer.join("\n");
    if (window.discourseAiTestClipboard) {
      window.discourseAiClipboard = text;
    }
    return text;
  }
});